import "./App.css";
import DeleteAccount from "./components/DeleteAccount/DeleteAccount";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import Homepage from "./Homepage/Homepage";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Homepage />} />
        <Route path="/app/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/app/deleterequest" element={<DeleteAccount />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
