import React from "react";
import { useState, useEffect, useRef } from "react";
import "./Homepage.css";
// import Person from "../components/Person/Person";
import BoloMini from "../assets/BoloMini.svg";
// import BoloIcon from "../assets/BoloIcon.svg";
import BoloImage from "../assets/BoloImage.svg";
// import BoloBack from "../assets/BoloBack.png";
import EnglishMain from "../assets/EnglishMain.svg";
import HindiMain from "../assets/HindiMain.svg";
import KannadaMain from "../assets/KannadaMain.svg";
import TamilMain from "../assets/TamilMain.svg";
import TeluguMain from "../assets/TeluguMain.svg";
import BengaliMain from "../assets/BengaliMain.svg";
import MalayalamMain from "../assets/MalayalamMain.svg";
import MarathiMain from "../assets/MarathiMain.svg";
import EnglishMain1 from "../assets/English1Main.svg";
// import EnglishSub from "../assets/EnglishSub.svg";
import PunjabiMain from "../assets/PunjabiMain.svg";
import GujaratiMain from "../assets/GujaratiMain.svg";
import AssameseMain from "../assets/AssameseMain.svg";
import PlayStore from "../assets/PlayStore.svg";
import AppStore from "../assets/AppStore.svg";
import Tick from "../assets/Tick.svg";
import { BaseURL } from "../BaseURL";
import axios from "axios";
// import Header from "../Header/Header";
// import { Dialogues } from "../components/Dialogue/Dialogue";
// import Map from "../components/Map/Map";

const Homepage = () => {
  // const [isVisible, setIsVisible] = useState(false);
  const [hasJumped, setHasJumped] = useState(false);
  const imageRef = useRef(null);
  const images = [
    EnglishMain,
    EnglishMain1,
    HindiMain,
    KannadaMain,
    TeluguMain,
    TamilMain,
    MalayalamMain,
    MarathiMain,
    GujaratiMain,
    PunjabiMain,
    BengaliMain,
    AssameseMain,
  ];
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  const [formValues, setFormValues] = useState({
    Email: "",
  });
  const [subscribe, setSubscribe] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [error, setError] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!hasJumped && currentImageIndex === 1) {
        setCurrentImageIndex(0);
        setHasJumped(true);
      } else {
        setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }
    }, 2400);

    return () => clearInterval(interval);
  }, [currentImageIndex, images.length, hasJumped]);

  useEffect(() => {
    if (imageRef1.current) {
      imageRef1.current.classList.add("add");
      const timeout = setTimeout(() => {
        imageRef1.current.classList.remove("add");
      }, 2300);

      return () => clearTimeout(timeout);
    }
  }, [currentImageIndex]);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // setIsVisible(true);
          observer.unobserve(entry.target);
        }
      });
    });

    const currentImageRef = imageRef.current;

    if (currentImageRef) {
      observer.observe(currentImageRef);
    }

    return () => {
      if (currentImageRef) {
        observer.unobserve(currentImageRef);
      }
    };
  }, []);
  const imageRef1 = useRef(null);
  const [timerStarted, setTimerStarted] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setTimerStarted(false);
      } else {
        setTimerStarted(true);
      }
    }, options);
    const currentImageRef = imageRef.current;

    if (currentImageRef) {
      observer.observe(currentImageRef);
    }

    return () => {
      if (currentImageRef) {
        observer.unobserve(currentImageRef);
      }
    };
  }, []);

  useEffect(() => {
    let timer;
    let count = 1;
    // const section = document.getElementById("Animation_Section");
    if (timerStarted) {
      timer = setInterval(() => {
        if (count === 36) {
          // section.scrollIntoView({ behavior: "smooth" });
        }
        count++;
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [timerStarted]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleEmail = async () => {
    if (
      [formValues.Email].some(
        (field) => field?.trim() === "" || !emailRegex.test(field)
      )
    ) {
      setError(true);
      return;
    } else {
      const response = await axios.post(
        `${BaseURL}/api/v1/users/SubscribeForm`,
        formValues,
        {
          header: {
            "content type": "application.json",
          },
        }
      );
      console.log(response.data);
      setFormValues({
        Email: "",
      });
      setError(false);
      setSubscribe(true);
    }
  };

  useEffect(() => {
    if (showPopup) {
      const input = document.getElementById("emailbox");
      if (input) {
        input.focus();
      }
    }
  });

  return (
    <div className="App">
      {showPopup && (
        <>
          <div
            className="Overlay"
            onClick={() => {
              setShowPopup(false);
            }}
          >
            <div
              className="Popup_Box"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <img src={BoloMini} alt="Bolo India" />
              <h1>Namaste!</h1>
              <p>We are on the way. we’ll reach you soon...</p>
              {subscribe ? (
                <>
                  <div className="Bolo_Subscribe">
                    <p style={{ color: "#0fba00" }}>
                      <strong>You're&nbsp;Subscribed</strong>
                    </p>
                    <img src={Tick} alt="Sunscribed" />
                  </div>
                </>
              ) : (
                <>
                  <div className="Bolo_Subscribe">
                    <div className="Subscribe_Button">
                      <input
                        id="emailbox"
                        type="email"
                        name="Email"
                        value={formValues.Email}
                        onChange={handleChange}
                        placeholder="Enter your E-mail"
                        required
                      />
                      <button onClick={handleEmail}>Send</button>
                    </div>
                    {error && (
                      <>
                        <h6>Please enter valid E-mail ID</h6>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      )}
      <div className="Homepage_Section">
        <div className="Snap_Section">
          <div className="Image_Section">
            <div className="Image_Sub_Section">
              {/* <div className="Image_Sub_Section_Map" ref={imageRef1}>
                <Map />
              </div> */}
              <div className="Bolo_Icon_Container">
                {/* <img className="Main_Logo" src={BoloIcon} alt="BoloIndia" /> */}
                <div className="Main_Moto">
                  <img className="Main_Logo" src={BoloImage} alt="BoloIndia" />
                  <h2>It's time, listen to India</h2>
                </div>
                <img
                  // className={`Main_Text ${
                  //   isTextVisible ? "visible" : "hidden"
                  // }`}
                  className="Main_Text"
                  ref={imageRef1}
                  src={images[currentImageIndex]}
                  alt="BoloIndia"
                />
              </div>
            </div>
            {/*<div className="Snap_Section">
              <div className="Image_Sub_Section">
                <div id="Animation_Section" className="Bolo_Icon_Container">
                  <div className="Images_Container">
                    <img
                      ref={imageRef}
                      className={`Main_Logo_1 ${isVisible ? "active" : ""}`}
                      src={BoloImage}
                      alt="BoloIndia"
                    />
                    <img
                      className={`Sub_Text ${isVisible ? "active" : ""}`}
                      src={EnglishSub}
                      alt="BoloIndia"
                    />
                  </div>
                  <Person positions={Dialogues} />
                </div>
              </div>
            </div>*/}
          </div>
        </div>
        <div className="Info_Section">
          <div className="Info_Contents">
            <p>We’ve created a space for every Indian</p>
            <p> to engage with each other</p>
            <p> on everything that’s INDIA.</p>
            <h3>Welcome to Bolo India!</h3>
          </div>
          <div className="App_Links">
            <div className="App_Info">
              <p>Get the app!</p>
              <div className="Apps">
                <img
                  src={PlayStore}
                  alt="PlayStore"
                  onClick={() => {
                    setShowPopup(true);
                  }}
                />
                <img
                  src={AppStore}
                  alt="AppStore"
                  onClick={() => {
                    setShowPopup(true);
                  }}
                />
              </div>
            </div>
            <h6>Made by Indians, Made in India for India.</h6>
          </div>
        </div>
        {/* <div className="Snap_Section">
          <div className="Content_Section">
            <div className="Bolo_Box">
              <div className="Bolo_Text_Container">
                <div className="Bolo_Container_T">
                  <h2>Made In India</h2>
                  <h3>
                    We know you’re on many platforms. Try us out! It’s a genuine
                    effort to connect with Indians on everything that matters to
                    you in India, from every nukkad & gully.
                  </h3>
                  <p>Made in India, this is your platform!</p>
                </div>
              </div>
              <div className="Bolo_Image_Container">
                <img src={Bolo} alt="BoloIndia" />
                <p>Hey, Bolo</p>
              </div>
            </div>
            <div className="Bolo_Box">
              <div className="Bolo_Image_Container">
                <img src={Bolo} alt="BoloIndia" />
                <p>Verified Profiles</p>
              </div>
              <div className="Bolo_Text_Container">
                <div className="Bolo_Container_T">
                  <h2>Genuine! No fakes</h2>
                  <h3>
                    There is clutter everywhere. We want to keep Bolo India
                    clean & tidy with genuine Indians on it minus the fake ones.
                  </h3>
                  <p>We authenticate you with your Aadhar.</p>
                </div>
              </div>
            </div>
            <div className="Bolo_Box">
              <div className="Bolo_Text_Container">
                <div className="Bolo_Container_T">
                  <h2>Our Children</h2>
                  <h3>
                    We love our children, & want to ensure their childhood
                    remains pristine. You can sign up with Bolo India as student
                    with access to everything that matters to you, minus the
                    ones that affects you.
                  </h3>
                  <p>Your college ID gets you in.</p>
                </div>
              </div>
              <div className="Bolo_Image_Container">
                <img src={Bolo} alt="BoloIndia" />
                <p>Student Content</p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Homepage;
