import React from "react";
import { useState, useEffect } from "react";
import "./Header.css";
import BoloLogo from "../assets/BoloLogo.svg";
// import Language from "../assets/Language.svg";

const Header = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setShow(!entry.isIntersecting);
      },
      {
        rootMargin: "0px",
        threshold: 0.5,
      }
    );

    const targetElement = document.querySelector(".Image_Sub_Section");

    if (targetElement) {
      observer.observe(targetElement);
    }

    return () => {
      if (targetElement) {
        observer.unobserve(targetElement);
      }
    };
  }, []);

  return (
    <header>
      <>
        <div className="Bolo_Header_Status">
          <div className="Bolo_Header">
            <a href="/">
              <div className="Logo_Section">
                <img className="Image" src={BoloLogo} alt="BoloIndia Logo" />
              </div>
            </a>
            {/* <div className="Language_Section">
          <img src={Language} alt="Language Change" />
          <p>Language</p>
        </div> */}
          </div>
        </div>
      </>
    </header>
  );
};

export default Header;
